
					@import './src/sass/variables';
					@import './src/sass/mixins';
				





































































































































































.block-inner {
	display: flex;
	flex-flow: row wrap;
	column-gap: $default_padding * 2;

	header {
		flex: 1 0 100%;
		margin-bottom: 2rem;
	}

	.items, .targets {
		flex: 1;
	}

	&.disabled {
		.items > li,
		.targets ol li {
			cursor: default;
		}
	}
}

ol {
	list-style-type: none;
	margin: 0;
	padding: 0;
	max-width: 500px;
}

.move-item {
	&.correct {
		box-shadow: 0 0 0 1px $color__green;
	}

	&.incorrect {
		box-shadow: 0 0 0 1px $color__red;
	}
}

.items > li,
.targets ol li {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	background: $color__white;
	font-size: 1.25rem;
	width: 100%;
	border: none;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	padding: 0.5rem 1rem;
	margin-bottom: $default_padding / 2;
	line-height: 1em;
	outline: none !important;
	transition: box-shadow 0.2s ease-out;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

.targets > li {
	.target-name {
		text-align: center;
		color: $color__dark_blue;
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	& > ol {
		min-height: 100px;
		border: 2px dashed $color__dark_blue;
		border-radius: $border_radius;
		padding: $default_padding * 0.5;
		position: relative;

		li {
			position: relative;
			z-index: 2;
		}

		&::before {
			content: 'Drop items here';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0.5;
			z-index: 1;
		}
	}
}

